export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    streetGetApi: false,
    streetData: {},
  },
  getters: {
    streetGetApi: state => state.streetGetApi,
    streetData: state => state.streetData
  },
  mutations: {
    changeSTREETGetApi(state, type) {
      state.streetGetApi = type
    },
    changeSTREETData(state, data) {
      state.streetData = Object.assign(state.streetData, data)
    },
  }
}