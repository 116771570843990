export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    nowOrgId: "12cf6ee284d511e8aabc00163e06cded", // a849dbae84d611e8aabc00163e06cded: 幕府西路orgId; 12cf6ee284d511e8aabc00163e06cded: 宝塔桥orgId
    nowOrgInfo: { // 当前所选街道的信息
      name: ""
    },
    slideTypeList: [{ // 页面底部按钮列表
      title: "功分自治",
      type: "point"
    }, {
      title: "民生保障",
      type: "lively"
    }, {
      title: "红邻党建",
      type: "party"
    }, {
      title: "平安稳定",
      type: "safe"
    }, {
      title: "城市管理",
      type: "city"
    }],
    slideTypeIndex: -1, // 当前所选底部按钮
    loadingPageHide: false, // loading页隐藏
    loadingPageHideTimer: 600,
    slideAnimationTime: 1000, // 边缘模块滑入的延迟时间
    slideRoomShow: false, // 边缘模块是否滑入可视区域
    slideDomCssTransitionStart: false, // slideRoom内部DOM动态样式是否开始
    slideDomCssTransitionTimeDelay: 1500, // slideRoom内部DOM动态样式什么时候开始
    eChartsLoad: false, // 判断echarts是否需要加载或重新加载
    slideShowType: "", // 当前显示类型: point => 功分自治; tool => 工具模式

    toolSlideShowType: 0, // 工具页面两侧弹窗 0: 左右两边都不显示, 1: 显示左边; 2: 都显示
    toolDotClickGroupType: "", // 工具页面标记点当前点击groupType

    refreshNumber: 0, // 轮询接口请求次数
    commonData: {},
    buildingId:"", //特殊人群楼栋id
    tagType:"",//特殊人群类型ID
  },
  getters: {
    nowOrgId: state => state.nowOrgId,
    nowOrgInfo: state => state.nowOrgInfo,
    slideTypeList: state => state.slideTypeList,
    slideTypeIndex: state => state.slideTypeIndex,
    loadingPageHide: state => state.loadingPageHide,
    loadingPageHideTimer: state => state.loadingPageHideTimer,
    slideAnimationTime: state => state.slideAnimationTime,
    slideRoomShow: state => state.slideRoomShow,
    slideDomCssTransitionStart: state => state.slideDomCssTransitionStart,
    slideDomCssTransitionTimeDelay: state => state.slideDomCssTransitionTimeDelay,
    eChartsLoad: state => state.eChartsLoad,
    slideShowType: state => state.slideShowType,
    toolSlideShowType: state => state.toolSlideShowType,
    toolDotClickGroupType: state => state.toolDotClickGroupType,
    buildingId: state => state.buildingId,
    tagType: state => state.tagType,
  },
  mutations: {
    changeOrgId(state, id) {
      state.nowOrgId = id
    },
    changeOrgInfo(state, data) {
      state.nowOrgInfo = data
    },
    changeSlideTypeIndex(state, index) {
      state.slideTypeIndex = index
    },
    changeLoadingPageHide(state, show) {
      state.loadingPageHide = show
    },
    changeSlideRoomShow(state, show) {
      state.slideRoomShow = show
    },
    changeSlideDomCssTransitionStart(state, start) {
      state.slideDomCssTransitionStart = start
    },
    changeEChartsLoad(state, data) {
      state.eChartsLoad = data
    },
    changeSlideShowType(state, type) {
      state.slideShowType = type
    },
    changeToolSlideShowType(state, type) {
      state.toolSlideShowType = type
    },
    changeToolDotClickGroupType(state, type) {
      state.toolDotClickGroupType = type
    },
    changeBuildingId(state, data) {
      state.buildingId = data
    },
    changeTagType(state, data) {
      state.tagType = data
    },
  },
  actions: {
    changeOrgIdAction({
      dispatch,
      commit
    }, newId) {
      if (newId !== this.state.nowOrgId) {
        this.commit('gfzz/changeGFZZGetApi', false)
        this.commit('msbz/changeMSBZGetApi', false)
        this.commit('hldj/changeHLDJGetApi', false)
        this.commit('pajk/changePAJKGetApi', false)
        this.commit('csgl/changeCSGLGetApi', false)
      }

    },
    changeSlideTypeAction({
      dispatch,
      commit
    }, type) {
      let { slideAnimationTime } = this.state

      dispatch("changeSlideRoomShowAction", false)
      if ("tool" == type) {
        commit('changeSlideShowType', type)
      } else {
        setTimeout(() => {
          dispatch("changeSlideRoomShowAction", true)
          commit('changeSlideShowType', type)
        }, slideAnimationTime)
      }
    },
    changeSlideRoomShowAction({
      commit
    }, data) {
      let {
        slideAnimationTime
      } = this.state
      commit('changeSlideRoomShow', data)
      setTimeout(() => {
        // 延迟加载slideRoom内部动态样式
        commit('changeSlideDomCssTransitionStart', data)
        commit('changeEChartsLoad', data)
      }, slideAnimationTime)
    },
    changeLoadingPageHideAction({
      commit
    }, data) {
      let {
        loadingPageHideTimer,
        slideAnimationTime
      } = this.state
      commit("changeLoadingPageHide", data)

      setTimeout(() => {
        commit('changeSlideRoomShow', data)

      }, loadingPageHideTimer)

      setTimeout(() => {
        // 延迟加载slideRoom内部动态样式
        commit('changeSlideDomCssTransitionStart', data)
        commit('changeEChartsLoad', data)
      }, (slideAnimationTime))
    },
    changeBuildingIdAction({commit}, data) {
      commit("changeBuildingId", data)
    },
    changeTagTypeAction({commit}, data) {
      commit("changeTagType", data)
    },
  }
}