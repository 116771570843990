export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    csglGetApi: false,
    csglData: {},
  },
  getters: {
    csglGetApi: state => state.csglGetApi,
    csglData: state => state.csglData
  },
  mutations: {
    changeCSGLGetApi(state, type) {
      state.csglGetApi = type
    },
    changeCSGLData(state, data) {
      state.csglData = Object.assign(state.csglData, data)
    },
  }
}