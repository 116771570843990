export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    msbzGetApi: false,
    msbzData: {},
  },
  getters: {
    msbzGetApi: state => state.msbzGetApi,
    msbzData: state => state.msbzData
  },
  mutations: {
    changeMSBZGetApi(state, type) {
      state.msbzGetApi = type
    },
    changeMSBZData(state, data) {
      state.msbzData = Object.assign(state.msbzData, data)
    },
  }
}