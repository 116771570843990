export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    hldjGetApi: false,
    hldjData: {},
  },
  getters: {
    hldjGetApi: state => state.hldjGetApi,
    hldjData: state => state.hldjData
  },
  mutations: {
    changeHLDJGetApi(state, type) {
      state.hldjGetApi = type
    },
    changeHLDJData(state, data) {
      state.hldjData = Object.assign(state.hldjData, data)
    },
  }
}