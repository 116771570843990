export default {
  namespaced: true,
  state: {
    cesiumMarkerList: [], // cesium标记点列表
    nowChooseMonomerInfo: {
      id: ""
    },
    nowChooseMonomerInfoObj: {
      id: ""
    },
    primitiveColorIdArr: [], // 已改变color的primitive
  },
  getters: {
    cesiumMarkerList: state => state.cesiumMarkerList,
    nowChooseMonomerInfo: state => state.nowChooseMonomerInfo,
    nowChooseMonomerInfoObj: state => state.nowChooseMonomerInfoObj,
    primitiveColorIdArr: state => state.primitiveColorIdArr
  },
  mutations: {
    changeCesiumMarkerList(state, list) {
      state.cesiumMarkerList = list
    },
    changeNowChooseMonomerInfoObj(state, data) {
      state.nowChooseMonomerInfoObj = data
    },
    changeNowMonomerInfo(state, data) {
      state.nowChooseMonomerInfo = data
    },
    changePrimitiveColorIdArr(state, id) {
      if (id) {
        state.primitiveColorIdArr.push(id)
      } else {
        state.primitiveColorIdArr = []
      }
    }
  },
  actions: {
    chooseMonomerAction({
      commit,
      dispatch
    }, data) {
      commit('changeNowMonomerInfo', data)
      dispatch("publicInfo/changeSlideType", "monomer")
    },
  }
}