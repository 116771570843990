import axios from 'axios'
import Msg from 'vue-message';
import qs from 'qs'
import store from '../store';
const apiBase = store.state.config.configInfo.apiBase
/**
 * 创建axios实例
 */
export default {
  install(Vue, opts = {}) {
    Vue.use(Msg, {
      text: 'Hello world',
      duration: 3000,
      background: 'rgba(7, 17, 27, 0.6)',
      position: 'center',
    });

    const newAxios = axios.create({
      baseURL: apiBase,
      timeout: 20000
    })

    const defaultApi = axios.create({
      timeout: 20000
    })

    /**
     * 接口GET请求方法
     */
    Vue.prototype.$getApi = (url, data = {}) => {
      return (newAxios.get(url, {
        params: {
          org_id: data.orgId ? data.orgId : store.state.publicInfo.nowOrgId,
          ...data
        }
      }).then(res => {
        return res.data
      }).catch(err => {
        console.error(err)
      }))
    }

    Vue.prototype.$get = (url, params) => {
      return defaultApi.get(url, {
        params: {
          ...params
        }
      }).then((resp) => resp.data);
    };


    Vue.prototype.$postApi = (url, data = {}) => {
      return new Promise((resolve, reject) => {
        newAxios.post(url, {
          org_id: data.orgId ? data.orgId : store.state.publicInfo.nowOrgId,
          ...data
        }).then(res => {
          resolve(res)
        }), err => {
          reject(err)
        }
      })
    }

    Vue.prototype.$postJsonApi = (url, data = {}) => {
      return new Promise((resolve, reject) => {
        axios.post((apiBase + url + "?org_id=" + (data.orgId ? data.orgId : store.state.publicInfo.nowOrgId)), {
          ...data
        }).then(res => {
          resolve(res)
        }), err => {
          reject(err)
        }
      })
    }
  }
}