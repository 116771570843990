import Vue from "vue";
import App from "./App.vue";
import http from "@/api/http";
import store from "./store";
import axios from "axios";
import router from "./router";
import "default-passive-events";
import "view-design/dist/styles/iview.css";
import * as echarts from "echarts";
import "tailwindcss/tailwind.css";
import "remixicon/fonts/remixicon.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import '@/assets/fonts/font.css';
// 导入swiper@5.4.5
import VueAwesomeSwiper from "vue-awesome-swiper";
// import 'swiper/swiper-bundle.css';
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

// import cesiumViewer from '@/assets/js/cesium/cesiumViewer'
// Vue.use(cesiumViewer)

// 这里也可以直接执行 toastRegistry()
// Vue.use(toastRegistry)

import VueMessage from "vue-message";
Vue.use(VueMessage);
Vue.use(http);
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// async load font
// @see https://github.com/typekit/webfontloader#custom
// FIXME 打包后可能路径不对
import WebFont from "webfontloader";
const fontCss = process.env.BASE_URL + "fonts/font.css";
window.onload = () =>
  void WebFont.load({
    custom: {
      families: ["PingFangSC-Regular", "ZhenyanGB-Regular"],
      urls: [fontCss],
    },
  });
