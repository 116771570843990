import Vue from 'vue'
import Vuex from 'vuex'

import publicInfo from "./module/publicInfo"
import config from "./module/config"
import cesiumInfo from "./module/cesiumInfo"
import street from "./module/street"
import gfzz from "./module/gfzz"
import msbz from "./module/msbz"
import hldj from "./module/hldj"
import pajk from "./module/pajk"
import csgl from "./module/csgl"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    gfzz,
    msbz,
    hldj,
    pajk,
    csgl,
    street,
    publicInfo,
    config,
    cesiumInfo,
  },
  state: {
    toolSlideShowType: 0, // 工具页面两侧弹窗 0: 左右两边都不显示, 1: 显示左边; 2: 都显示
    toolDotClickGroupType: "", // 工具页面标记点当前点击groupType

    winShow: false, // 抽屉弹窗显示
    winShowType: '', // 抽屉弹窗显示内容

    expertShow: false, // 专家弹窗显示
    expertShowId: 0, // 专家弹窗显示内容


    refreshNumber: 0, // 轮询接口请求次数
    commonData: {},

    findPersonValue: "", // 人找房关键字

    chooseMonomerType: "build",
    cesiumClickType: "", // 选择cesium鼠标操作类型, 例如: monomer => 单体化; makerPoint => 标记点; area => 区域划分
    cesiumMakeNewMonomer: {
      /**
       * type: "", // 单体类型, 例如: build: 楼栋; unit: 单元; house: 室
       * positionList: null, // 经纬度坐标
       * cartesianList: null, // 笛卡尔坐标系坐标
       */
    },
    cesiumMakeNewPoint: {
      /**
       * type: "", // 打标记点的类型, 例如: video => 摄像头;
       * position: null, // 经纬度坐标
       * cartesian: null, // 笛卡尔坐标系坐标
       */
    },
    cesiumMakeNewArea: {
      /**
       * type: "", // 打标记点的类型, 例如: grid: 网格; community => 小区;
       * positionList: null, // 经纬度坐标
       * cartesianList: null, // 笛卡尔坐标系坐标
       * centerPosition: null, // 中心点坐标
       * centerCartesian: null // 中心点笛卡尔坐标
       */
    },
    nowChooseMonomerInfo: {
      id: ""
    },
    nowChooseMonomerInfoObj: {
      id: ""
    },
    primitiveColorIdArr: [], // 已改变color的primitive
  },
  getters: {},
  mutations: {
    changeRefreshNumber(state, num) {
      state.refreshNumber = num
    },
    changeToolDotClickGroupType(state, type) {
      state.toolDotClickGroupType = type
    },
    changeCommonData(state, data) {
      state.commonData = Object.assign(state.commonData, data)
    },
    changeFindPersonValue(state, value) {
      state.findPersonValue = value
    },
    changeWinShow(state, show) {
      state.winShow = show
    },
    changeExpertShow(state, show) {
      state.expertShow = show
    },
    changeWinShowType(state, type) {
      state.winShowType = type
    },
    changeExpertShowId(state, type) {
      state.expertShowId = type
    },
    changeChooseMonomerType(state, type) {
      state.chooseMonomerType = type
    },
    changeToolSlideShowType(state, type) {
      state.toolSlideShowType = type
    },
    changeCesiumClickType(state, data) {
      state.cesiumClickType = data.mainType
      data.makeNew = data.makeNew == false ? false : true
      if (data.makeNew) {
        switch (data.mainType) {
          case "monomer": // 单体化
            state.cesiumMakeNewMonomer = data.monomer
            break
          case "makePoint": // 标记点
            state.cesiumMakeNewPoint = data.point
            break
          case "makeArea": // 区域划分
            state.cesiumMakeNewArea = data.area
            break
        }
      }

    },
    changeNowMonomerInfo(state, data) {
      state.nowChooseMonomerInfo = data
    },
    changeNowChooseMonomerInfoObj(state, data) {
      state.nowChooseMonomerInfoObj = data
    },
    changePrimitiveColorIdArr(state, id) {
      if (id) {
        state.primitiveColorIdArr.push(id)
      } else {
        state.primitiveColorIdArr = []
      }
    }
  },
  actions: {
    changeToolDotClickGroupTypeAction({
      commit
    }, type) {
      commit("changeToolDotClickGroupType", type)
    },
    changeRefreshNumberAction({
      commit
    }, num) {
      commit("changeRefreshNumber", num)
    },
    changeCommonDataAction({
      commit
    }, data) {
      commit("changeCommonData", data)
    },
    changeWinShowAction({
      commit
    }, type) {
      commit("changeWinShow", type)
    },
    changeExpertShowAction({
      commit
    }, type) {
      commit("changeExpertShow", type)
    },
    changeWinShowTypeAction({
      commit
    }, type) {
      commit("changeWinShowType", type)
    },
    changeExpertShowIdAction({
      commit
    }, type) {
      commit("changeExpertShowId", type)
    },
    changeFindPersonValueAction({
      commit
    }, value) {
      commit("changeFindPersonValue", value)
    },
    changeCesiumClickTypeAction({
      commit
    }, data) {
      commit('changeCesiumClickType', data)
    },
    changeChooseMonomerTypeAction({
      commit
    }, type) {
      commit('changeChooseMonomerType', type)
    },
    changeNowMonomerInfoAction({
      commit
    }, data) {
      commit('changeNowMonomerInfo', data)
    },
    chooseMonomerAction({
      commit,
      dispatch
    }, data) {
      commit('changeNowMonomerInfo', data)
      dispatch("changeSlideType", "monomer")
    },
    changeNowChooseMonomerInfoObjAction({
      commit
    }, data) {
      commit('changeNowChooseMonomerInfoObj', data)
    },
    changePrimitiveColorIdArrAction({
      commit
    }, id = "") {
      commit('changePrimitiveColorIdArr', id)
    }
  },
})