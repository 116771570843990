export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    pajkGetApi: false,
    pajkData: {},
  },
  getters: {
    pajkGetApi: state => state.pajkGetApi,
    pajkData: state => state.pajkData
  },
  mutations: {
    changePAJKGetApi(state, type) {
      state.pajkGetApi = type
    },
    changePAJKData(state, data) {
      state.pajkData = Object.assign(state.pajkData, data)
    },
  }
}