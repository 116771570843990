export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    pageTitle: "宝塔桥街道红邻帮智慧社区大脑", // 项目顶部标题
    configInfo: {
      // 基础配置
      baseAvatar:
        "https://nbol.oss-cn-beijing.aliyuncs.com/static/bright/touxiangnan.png",
      apiBase: "https://nbol.liaodukeji.com",
      websocketApi: "ws://nbol.liaodukeji.com",
    },
  },
  getters: {
    pageTitle: (state) => state.pageTitle,
    configInfo: (state) => state.configInfo,
  },
  mutations: {},
};
