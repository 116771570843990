export default {
  namespaced: true, // 开启namespaced, 使改模块称谓命名空间模块
  state: {
    gfzzGetApi: false,
    gfzzData: {},
  },
  getters: {
    gfzzGetApi: (state) => state.gfzzGetApi,
    gfzzData: (state) => state.gfzzData,
  },
  mutations: {
    changeGFZZGetApi(state, type) {
      state.gfzzGetApi = type;
    },
    changeGFZZData(state, data) {
      state.gfzzData = Object.assign(state.gfzzData, data);
    },
  },
};
